.loader-container {
  display: flex;
  margin-bottom: 30px;
}

.left {
  flex: 1;
}

.right {
  flex: 4;
}

.loader-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100vh;
  background-color: rgb(255, 255, 255);
}


/* dot loader css */

.loader-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 10vh;
  /* height: 100vh; */
}

.loader-section h6 {
  margin: 0;
}

.dot-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100px; */
}

.dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: orange;
  margin: 0 5px;
  animation: dot-animation 1.5s infinite ease-in-out;
}


.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes dot-animation {

  0%,
  80%,
  100% {
    transform: scale(0);
    background-color: orange;
  }

  80% {
    background-color: red;
  }

  40% {
    transform: scale(1);
  }
}