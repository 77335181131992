.custom-search-input::-webkit-search-cancel-button,
.custom-search-input::-webkit-search-decoration {
    display: none;
}


.search-component {
    position: relative;
}

.custom-clear-button {
    position: absolute;
    top: 50%;
    right: 37px;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: rgb(198, 23, 23);
    cursor: pointer;
    font-size: 1px;
}

.custom-clear-button:hover {
    color: darkred;
}


.search-button {
    margin-left: -9px;
    cursor: pointer;
}