.timeline-1 {
    border-left: 1px solid #746871;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    background: rgba(214, 194, 211, 0.09);
    margin: 0 auto;
    position: relative;
    /* padding: 50px; */
    list-style: none;
    text-align: left;
    max-width: 100%;
}

@media (max-width: 767px) {
    .timeline-1 {
        max-width: 98%;
        padding: 25px;
    }
}

.timeline-1 .event {
    border-bottom: 1px dashed #000;
    padding-bottom: 25px;
    margin-bottom: 25px;
    position: relative;
}

@media (max-width: 767px) {
    .timeline-1 .event {
        padding-top: 30px;
    }
}

.timeline-1 .event:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
}

.timeline-1 .event:before,
.timeline-1 .event:after {
    position: absolute;
    display: block;
    top: 0;
}

/* 
.timeline-1 .event:before {
    left: -207px;
    content: attr(data-date);
    text-align: right;
    font-weight: 100;
    font-size: 0.9em;
    min-width: 120px;
}

@media (max-width: 767px) {
    .timeline-1 .event:before {
        left: 0px;
        text-align: left;
    }
}

.timeline-1 .event:after {
    -webkit-box-shadow: 0 0 0 1.5px #53384d;
    box-shadow: 0 0 0 1.5px #53384d;
    left: -55.8px;
    background: #fff;
    border-radius: 50%;
    height: 9px;
    width: 9px;
    content: "";
    top: 5px;
}

@media (max-width: 767px) {
    .timeline-1 .event:after {
        left: -31.8px;
    }
} */




/* adapted from http://maxwells.github.io/bootstrap-tags.html */
.tag {
    font-size: 14px;
    padding: .3em .4em .4em;
    margin: 0 .1em;
}

.tag a {
    color: #bbb;
    cursor: pointer;
    opacity: 0.6;
}

.tag a:hover {
    opacity: 1.0
}

.tag .remove {
    vertical-align: bottom;
    top: 0;
}

.tag a {
    margin: 0 0 0 .3em;
}

.tag a .glyphicon-white {
    color: #fff;
    margin-bottom: 2px;
}